//owner User Data
import moment from "moment-timezone";
import config from "@/config/app";
import { mapGetters } from "vuex";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
import FieldsFormatHelper from "./FieldsFormatHelper";
export default {
  name: "Content Format Helper",
  data() {
    return {
      currencySymbolObject: {
        USD: "$",
        CAD: "$",
        AUD: "$",
        INR: "&#8377;",
        EUR: "€",
      },
      timeOffset: new Date().getTimezoneOffset(),
      ownerUserData: {},
      defaultDateFormat: this.getDefaultDateFormat
        ? this.getDefaultDateFormat
        : "MM-DD-YYYY",
    };
  },
  computed: {
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getDefaultDateFormat","getDefaultTimeFormat"]),
    ...mapGetters("settings", [
      "getApplicationSettings",
      "getDocOwnerApplicationSettings",
      "getUpdateApplicationSettingsStatus",
    ]),
  },
  mixins: [TemplateBuilderHelper, FieldsFormatHelper],
  methods: {
    getParsedTextContent(
      emailTemplate,
      dataArray,
      allFields,
      currentEntity,
      isFromPagination
    ) {
      //outside data loop
      let fieldsObject = this.getFieldsObject(allFields),
        ejsTemplate = emailTemplate.content,
        variablesObj = {
          actionButtonFieldKeys: [],
          childDataTableIdsObj: {},
          childDataTableFields: [],
          fileFields: [],
          filesDataObj: {},
          tableFileFields : []
        },
        actualInputTypeObject = {};
      let mentionedFieldVariables = this.getMentionableFieldsFromText(
        ejsTemplate,
        /\[\[(.*?)\]\]/g
      );
      //returning original template if no fields are mentioned
      if (!mentionedFieldVariables?.length) {
        return dataArray;
      }
      mentionedFieldVariables.map((fieldKey) => {
        let field = fieldsObject[fieldKey];
        let inputType = this.getActualInputTypeOfField(field);
        actualInputTypeObject[fieldKey] = inputType || "SINGLE_LINE_TEXT";
        switch (inputType) {
          case "ACTION_BUTTON":
            variablesObj.actionButtonFieldKeys.push(fieldKey);
            break;
          case "ENTITY_TABLE":
            variablesObj.childDataTableFields.push(fieldKey);
            break;
          case "FILE":
            variablesObj.fileFields.push(fieldKey);
            break;
          default:
            break;
        }
      });

      //inside data loop
      let newDataArray = (dataArray || []).map((data) => {
        ejsTemplate = emailTemplate.content;
        ejsTemplate = this.replaceCompanyAndCustomVariables(
          ejsTemplate,
          data,
          currentEntity
        );
        let dataObject = {};
        //have to change this
        let asyncVariablesObj = {};
        data?.templates_data?.map((e) => {
          dataObject[e.template_id.toString()] = e;
        });
        // let variablesPromise =
        mentionedFieldVariables.map((fieldKey) => {
          let splitArray = fieldKey.split("#");
          if (splitArray?.length == 1) return; //relational data tables
          let templateId = splitArray[0];
          let templateKey = splitArray[1];
          if (templateKey.includes(":")) {
            templateKey = templateKey.split(":")[1];
          }
          let existedDataObject = dataObject[templateId.toString()];
          let existedData = isFromPagination
            ? data["entityData"]?.[templateId?.toString()]
            : existedDataObject?.template_data_id?.template_data;
          let field = fieldsObject[fieldKey];
          let inputType = actualInputTypeObject[fieldKey];
          //Need to check with multiple entity tables
          if (inputType == "ENTITY_TABLE" && existedData[templateKey]?.length && !field.data_table_field_auto_fill) {
            variablesObj.childDataTableIdsObj[data._id] =
              existedData[templateKey];
            (field.data_table_columns || []).map(fd => {
              if(fd.inputType == 'FILE') {
                variablesObj.tableFileFields.push(`${field.entity_id}#${fd.key}`)
              }
            })
          } 
          // else if (inputType == "FILE" && existedData[templateKey]) {
          //   existedData[templateKey] = {
          //     ...existedData[templateKey],
          //     fieldKey
          //   }
          //   if (variablesObj.filesDataObj[data._id]?.length) {
          //     variablesObj.filesDataObj[data._id].push(
          //       existedData[templateKey]
          //     );
          //   } else {
          //     variablesObj.filesDataObj[data._id] = [existedData[templateKey]];
          //   }
          // }

          if (!existedData) {
            ejsTemplate = ejsTemplate.replace(`[[${fieldKey}]]`, "--");
            return null;
          }
          if (splitArray.length == 3) {
            let advancedField = splitArray[2];
            switch (advancedField) {
              case "pay":
                asyncVariablesObj[fieldKey] = asyncVariablesObj[fieldKey]
                  ? asyncVariablesObj[fieldKey]
                  : "--";
                ejsTemplate = ejsTemplate.replace(
                  `[[${fieldKey}]]`,
                  asyncVariablesObj[fieldKey]
                );
                break;
              default:
                break;
            }
          } else {
            if (![null, undefined, ""].includes(existedData[templateKey])) {
              if (
                !field ||
                (field.properties?.filed_content &&
                  field.properties?.filed_content == "Hide" &&
                  !emailTemplate.show_hidden_fields) ||
                field?.input_type == "SIGNATURE"
              ) {
                ejsTemplate = ejsTemplate.replace(`[[${fieldKey}]]`, "");
                return null;
              }
              let fieldValue = this.getParsedFieldValue(
                existedData,
                templateKey,
                asyncVariablesObj,
                field,
                inputType,
                emailTemplate,
                currentEntity,
                variablesObj,
                data._id
              );
              if (inputType == "IMAGE" || inputType == "GIF") {
                let reString = `https://app-esigns-io.s3.amazonaws.com/ck_editor_assets/MicrosoftTeams-image_8-1716639572596.png?field=[[${fieldKey}]]`;
                if (ejsTemplate.includes(reString)) {
                  ejsTemplate = ejsTemplate.replace(reString, fieldValue);
                }
              } else if (inputType == "ENTITY_TABLE") {
                return;
              } else {
                if (["", null, undefined, NaN].includes(fieldValue))
                  fieldValue = "-";
                if (inputType != "ACTION_BUTTON") {
                  ejsTemplate = ejsTemplate.replace(
                    `[[${fieldKey}]]`,
                    fieldValue
                  );
                }
              }
            } else {
              let replaceString = "-";
              //it can have autofill rows
              if(inputType == "ENTITY_TABLE" && field.data_table_field_auto_fill && field.entity_id) {
                return
              }
              ejsTemplate = ejsTemplate.replace(
                `[[${fieldKey}]]`,
                replaceString
              );
            }
          }
        });
        data.ejsTemplate = ejsTemplate;
        if (emailTemplate.relationalTableData?.length) {
          let relationalDataDataTables =
            emailTemplate.relationalTableData.filter(
              (e) => e.typee == "dataTable"
            );
          (relationalDataDataTables || []).map((table) => {
            let field = fieldsObject[table.data_table_key];
            if(!field)
              return
            let replaceString;
            if (field.inputType == "DATA_TABLE") {
              replaceString = this.getParsedDataTable(
                data.entityData?.[field.template_id],
                field.key,
                table,
                field,
                emailTemplate,
                currentEntity,
                variablesObj,
                data._id
              );
              data.ejsTemplate = data.ejsTemplate.replace(
                `[[DataTable-${table.name}]]`,
                replaceString
              );
            } else if(field.inputType == "ENTITY_TABLE" && !field.data_table_field_auto_fill) {
              let existedDataObject = dataObject[field.template_id.toString()];
              let existedData = isFromPagination
                ? data["entityData"]?.[field.template_id?.toString()]
                : existedDataObject?.template_data_id?.template_data;
                let key = field.key?.includes("#") ? field.key.split("#")[1] : field.key;
                variablesObj.childDataTableIdsObj[data._id] = existedData[key];
                (field.data_table_columns || []).map(fd => {
                  if(fd.inputType == 'FILE') {
                    variablesObj.tableFileFields.push(`${field.entity_id}#${fd.key}`)
                  }
                })
            }
          });
        }
        return data;
      });
      return [newDataArray, variablesObj];
    },
    async replaceAsyncVariables(
      dataArray,
      allFieldsObj,
      dataTableDataObj,
      responseObj,
      variablesObj,
      emailTemplate,
      currentEntity
    ) {
      let relationalData = responseObj?.relationalData;
      let filesUrlObj = responseObj?.filesUrlObj || {};
      let autoFillTablesObj = responseObj?.autoFillTableData || {};
      await Promise.all(
        dataArray.map(async (data) => {
          (variablesObj.childDataTableFields || []).map((fieldKey) => {
            let splitArray = fieldKey.split("#");
            if (splitArray?.length == 1) return; //relational data tables
            let field = allFieldsObj[fieldKey];
            if(!field)
              return
            let tableForm = dataTableDataObj, tableKey = data._id;
            if(autoFillTablesObj?.[data._id]?.[field.entity_id.toString()]) {
              let rows = [];
              tableForm = autoFillTablesObj[data._id];
              (tableForm[field.entity_id.toString()] || []).map(eData => {
                let form = {};
                form["keyIndex"] = (Math.random() + 1).toString(36).substring(7);
                form["_id"] = eData._id;
                field.data_table_columns?.map(f => {
                  if (
                    eData?.entityData?.[f.key.split("@")[0]]?.[f.key.split("@")[1]]
                  ) {
                    form[f.key] =
                      eData.entityData[f.key.split("@")[0]][f.key.split("@")[1]];
                    if (
                      Object.keys(eData.entityData[f.key.split("@")[0]]).includes(
                        `${f.key.split("@")[1]}/name`
                      )
                    ) {
                      form[`${f.key}/name`] =
                        eData.entityData[f.key.split("@")[0]][
                          `${f.key.split("@")[1]}/name`
                        ];
                    }
                    if (
                      Object.keys(eData.entityData[f.key.split("@")[0]]).includes(
                        `${f.key.split("@")[1]}_code`
                      )
                    ) {
                      form[`${f.key}_code`] =
                        eData.entityData[f.key.split("@")[0]][
                          `${f.key.split("@")[1]}_code`
                        ];
                    }
                  }
                  if (f.input_type === "PAY_BUTTON") {
                    let field = f.key.split("@");
                    form[`${f.key}`] = eData.entityData[field[0]][field[1]];
                    form[`${f.key}_history`] =
                      eData.entityData[field[0]][`${field[1]}_history`] || [];
                    form[`${f.key}_info`] =
                      eData.entityData[field[0]][`${field[1]}_info`] || {};
                    form[`${f.key}_cust_info`] =
                      eData.entityData[field[0]][`${field[1]}_cust_info`] || {};
                    form[`${field[0]}@${f.validations.entityVariable.key}`] =
                      eData.entityData[field[0]][f.validations.entityVariable.key];
                  }
                });
                // let row = {
                //   fields: this.data.data_table_columns,
                //   form: form
                // };
                // this.rowsData.push(row);
                rows.push(form);
              });
              tableForm[field.entity_id.toString()] = rows;
              tableKey = field.entity_id.toString();
            }
            let replaceString =
              this.getDataTableView(
                field,
                tableForm,
                tableKey,
                emailTemplate,
                currentEntity
              ) || "";
            data.ejsTemplate = data.ejsTemplate.replace(
              `[[${fieldKey}]]`,
              replaceString
            );
          });
          if (emailTemplate.relationalTableData.length) {
            let entityFieldsObj = {};
            //Getting all Relational entity fields
            await Promise.all(
              emailTemplate.relationalTableData.map(async (table) => {
                if (table.typee == "relationalTable") {
                  let entityFields = await this.fetchEntityDetails(
                    table.entity_id,
                    true,
                    false,
                    false,
                    false,
                    true,
                    false
                  );
                  entityFieldsObj[table.entity_id.toString()] = entityFields;
                }
              })
            );
            emailTemplate.relationalTableData.map((table) => {
              if (table.typee == "dataTable") {
                let field = allFieldsObj[table.data_table_key];
                if(!field)
                  return;
                let tableForm = dataTableDataObj, tableKey = data._id;
                if(autoFillTablesObj?.[data._id]?.[field.entity_id.toString()]) {
                  let rows = [];
                  tableForm = autoFillTablesObj[data._id];
                  (tableForm[field.entity_id.toString()] || []).map(eData => {
                    let form = {};
                    form["keyIndex"] = (Math.random() + 1).toString(36).substring(7);
                    form["_id"] = eData._id;
                    field.data_table_columns?.map(f => {
                      if (
                        eData?.entityData?.[f.key.split("@")[0]]?.[f.key.split("@")[1]]
                      ) {
                        form[f.key] =
                          eData.entityData[f.key.split("@")[0]][f.key.split("@")[1]];
                        if (
                          Object.keys(eData.entityData[f.key.split("@")[0]]).includes(
                            `${f.key.split("@")[1]}/name`
                          )
                        ) {
                          form[`${f.key}/name`] =
                            eData.entityData[f.key.split("@")[0]][
                              `${f.key.split("@")[1]}/name`
                            ];
                        }
                        if (
                          Object.keys(eData.entityData[f.key.split("@")[0]]).includes(
                            `${f.key.split("@")[1]}_code`
                          )
                        ) {
                          form[`${f.key}_code`] =
                            eData.entityData[f.key.split("@")[0]][
                              `${f.key.split("@")[1]}_code`
                            ];
                        }
                      }
                      if (f.input_type === "PAY_BUTTON") {
                        let field = f.key.split("@");
                        form[`${f.key}`] = eData.entityData[field[0]][field[1]];
                        form[`${f.key}_history`] =
                          eData.entityData[field[0]][`${field[1]}_history`] || [];
                        form[`${f.key}_info`] =
                          eData.entityData[field[0]][`${field[1]}_info`] || {};
                        form[`${f.key}_cust_info`] =
                          eData.entityData[field[0]][`${field[1]}_cust_info`] || {};
                        form[`${field[0]}@${f.validations.entityVariable.key}`] =
                          eData.entityData[field[0]][f.validations.entityVariable.key];
                      }
                    });
                    rows.push(form);
                  });
                  tableForm[field.entity_id.toString()] = rows;
                  tableKey = field.entity_id.toString();
                }
                let replaceString;
                if (field.inputType == "ENTITY_TABLE") {
                  replaceString = this.getParsedDataTable(
                    tableForm,
                    tableKey,
                    table,
                    field,
                    emailTemplate,
                    currentEntity,
                    variablesObj,
                    data._id
                  );
                }
                data.ejsTemplate = data.ejsTemplate.replace(
                  `[[DataTable-${table.name}]]`,
                  replaceString
                );
              } else {
                //update this
                if(relationalData?.[data._id]) {
                  let preparedRelationShipDataTable = this.getRelationalDataTable(
                    relationalData[data._id]?.[table.entity_id],
                    entityFieldsObj[table.entity_id.toString()],
                    table.selectedFields,
                    emailTemplate,
                    currentEntity
                  );
                  data.ejsTemplate = data.ejsTemplate.replace(
                    `[[DataTable-${table.name}]]`,
                    preparedRelationShipDataTable || ""
                  );
                }
              }
            });
          }
          // if(variablesObj?.filesDataObj[data._id]) {
          //   (variablesObj.fileFields || []).map((fieldKey) => {
          //     let splitArray = fieldKey.split("#");
          //     if (splitArray?.length == 1) return;
          //     // let field = allFieldsObj[fieldKey];
          //     // let replaceString = "";
          //     // let filesArray = variablesObj.filesDataObj[data._id];
          //     // if(filesArray?.length) {
          //     //   filesArray.map(file => {
          //     //     if(file.files?.length) {
          //     //       file.files.map(eachFile => {
          //     //         let filepath = `template-data-documents/${this.sanitizeFileName(eachFile.name)}`;
          //     //         if(filesUrlObj[filepath])
          //     //           replaceString += `<a target="MsgWindow" href="${filesUrlObj[filepath]}">${eachFile.name}</a> ,`;
          //     //       })
          //     //     } else {
          //     //       let filepath = `template-data-documents/${this.sanitizeFileName(file.name)}`;
          //     //       if(filesUrlObj[filepath])
          //     //         replaceString += `<a target="MsgWindow" href="${filesUrlObj[filepath]}">${file.name}</a>`;
          //     //     }
          //     //   })
          //     // } 

          //     // data.ejsTemplate = data.ejsTemplate.replace(
          //     //   `[[${fieldKey}]]`,
          //     //   replaceString
          //     // );
          //   });
          // }
          if(Object.keys(filesUrlObj)?.length) {
            (Object.keys(filesUrlObj) || []).map( e => {
              data.ejsTemplate = data.ejsTemplate.replaceAll(
                e.replace(`template-data-documents/`,'file-'),
                `<a style='color: #409EFF' href="${filesUrlObj[e]}" onclick="window.open(this.href, 'Snopzer',
                'left=120,top=80,width=1100,height=500,toolbar=1,resizable=0'); return false;" >${e.replace(`template-data-documents/`,'')}</a>`
              );
            })
          }
        })
      );
      return dataArray;
    },
    getFieldsObject(allFields, target = "key") {
      let fieldsObject = {};
      allFields.map((e) => {
        fieldsObject[e[target]] = e;
      });
      return fieldsObject;
    },
    getMentionableFieldsFromText(text, regex = /\[\[(.*?)\]\]/g) {
      let mentionedFields = [];
      let match;
      while ((match = regex.exec(text)) !== null) {
        mentionedFields.push(match[1]);
      }
      return mentionedFields;
    },
    getFormattedTimeValue(data, format) {
      if (!data) {
        return "";
      }
      data = data.toString();
      // If time is saved as "00:00:00", add current date to it
      if (!data.includes("T")) {
        let dateString = moment().format("YYYY-MM-DD");
        data = dateString + "T" + data;
      }
      let time = data.split(".")[0].replace("T", " ") || data;
      return moment(time).format(format);
    },
    getActualInputTypeOfField(field) {
      let inputType;
      if (!field) return "SINGLE_LINE_TEXT";
      if (field.input_type == "FORMULA") {
        if (field.validations.currency) {
          inputType = "CURRENCY";
        } else if (field?.result_type) {
          inputType = field.result_type;
        }
      } else if (
        field.input_type == "PAYMENT_VARIABLE" ||
        field.input_type == "ENTITY_VARIABLE"
      ) {
        inputType = field.inputType;
      }
      return inputType ? inputType : field.input_type;
    },
    sanitizeFileName(name){
      return name?.replace(/\//g, "-").replace(/ /g, "_").replace(/[()]/g, "") || "";
    },
    replaceCompanyAndCustomVariables(text, data, currentEntity) {
      let dataArray = [
        "about_company_text",
        "email_domain",
        "email_footer_text",
        "legal_name",
        "name",
        "logo",
      ];
      let customVariables = this.getMentionableFieldsFromText(
        text,
        /\(\(([^)]+)\)\)/g
      );
      if (!customVariables?.length) return text;
      let ownerUserData = this.ownerUserData;
      let companyVariables = [],
        customMentionedVariables = [];
      customVariables.map((e) => {
        if (dataArray.includes(e)) {
          companyVariables.push(e);
        } else {
          customMentionedVariables.push(e);
        }
      });
      if (companyVariables?.length) {
        companyVariables.map((e) => {
          if (this.getCompanyDetails[e] && e != "logo") {
            text = text.replace(`((${e}))`, this.getCompanyDetails[e]);
          } else if (e == "logo") {
            if (!this.getCompanyDetails[e]) {
              this.getCompanyDetails[
                e
              ] = `https://esigns.io/images/logo-dark.png`;
            }
            text = text.replace(
              `((${e}))`,
              `<br><img alt="image field" src="${this.getCompanyDetails[e]}" style="width: 50px" />`
            );
          } else {
            text = text.replace(`((${e}))`, "--");
          }
        });
      }

      if (customMentionedVariables && customMentionedVariables.length) {
        let viewType = "STANDARD";
        if (currentEntity.entity_type == "INDIVIDUAL") {
          viewType = "PROFILE";
        }
        let replaceString;
        let variablesData = {
          current_date: moment().format(this.defaultDateFormat) || "-",
          current_date_time:
            moment().format(`${this.defaultDateFormat} HH:mm`) || "-",
          current_time: moment().format("HH:mm") || "-",
          first_name: this.getAuthenticatedUser["first_name"] || "--",
          last_name: this.getAuthenticatedUser["last_name"] || "--",
          email: this.getAuthenticatedUser["email"] || "--",
          phone: this.getAuthenticatedUser["phone"] || "--",
          address: this.getAuthenticatedUser["address"] || "--",
          owner_first_name: ownerUserData.first_name || "",
          owner_last_name: ownerUserData.last_name || "",
          owner_email: ownerUserData.email || "",
          owner_phone: ownerUserData.phone || "",
          owner_address: ownerUserData.address || "--",
          redirect_link_entity_data_edit: `<a style="text-decoration: none; color:#409EF;" href="${
            config.APP_URL
          }/entity/edit/${
            currentEntity._id
          }?key=${this.getRandomKey()}&dataId=${
            data._id
          }&layout=${viewType}">Edit Data</a>`,
          redirect_link_entity_data_view: `<a style="text-decoration: none; color:#409EF;" href="${
            config.APP_URL
          }/entity/view/${
            currentEntity._id
          }?key=${this.getRandomKey()}&dataId=${
            data._id
          }&layout=${viewType}">View Data</a>`,
          redirect_link_ap_entity_data_edit: `<a style="text-decoration: none; color:#409EF;" href="${
            config.APP_URL
          }/ap/entity-execute/${
            currentEntity._id
          }?key=${this.getRandomKey()}&dataId=${
            data._id
          }&menu_id=&filter=&layout=${viewType}&companySlug=${
            this.getCompanyDetails.slug
          }&type=AUfYWb7SC6U=">Edit Data (Ap)</a>`,
          redirect_link_ap_entity_data_view: `<a style="text-decoration: none; color:#409EF;" href="${
            config.APP_URL
          }/ap/entity-view/${
            currentEntity._id
          }?key=${this.getRandomKey()}&dataId=${
            data._id
          }&menu_id=&filter=&layout=${viewType}&companySlug=${
            this.getCompanyDetails.slug
          }&type=AUfYWb7SC6U=">View Data (Ap)</a>`,
        };
        customVariables.map((field) => {
          replaceString = variablesData[field] ? variablesData[field] : "--";
          text = text.replace(`((${field}))`, replaceString);
        });
      }

      return text;
    },
    getParsedFieldValue(
      form,
      key,
      asyncVariablesObj,
      field,
      inputType,
      emailTemplate,
      currentEntity,
      variablesObj,
      dataId,
      isFromDataTable = false
      //   isRelationalDataCall = false
    ) {
      if(!form[key])
        return ""
      let replaceString = "",
        format,
        timeVar1,
        timeVar2,
        formattedValue,
        currencyType;
      //   let inputType = this.getActualInputTypeOfField(field);
      switch (inputType) {
        case "ENTITY":
          replaceString = form[`${key}/name`];
          break;
        case "DATE":
          format = this.defaultDateFormat;
          replaceString = this.getFormattedTimeValue(form[key], format) || "-";
          break;
        case "TIME":
          format = this.getDefaultTimeFormat == 12 ? "HH:mm A" : "HH:mm";
          replaceString = this.getGlobalFormattedTime(form[key]) || "-";
          break;
        case "DATE_TIME":
          format = this.defaultDateFormat + ` ${this.getDefaultTimeFormat == 12 ? "HH:mm A" : "HH:mm"}`;
          replaceString = this.getGlobalFormattedDateTime(form[key]) || "-";
          break;
        case "TIME_RANGE":
          format = this.getDefaultTimeFormat == 12 ? "HH:mm A" : "HH:mm";
          timeVar1 = this.getGlobalFormattedTime(form[key][0], true) || "-";
          timeVar2 = this.getGlobalFormattedTime(form[key][1], true) || "-";
          replaceString = `${timeVar1} - ${timeVar2}`;
          break;
        case "DATE_RANGE":
          format = this.defaultDateFormat;
          timeVar1 = this.getFormattedTimeValue(form[key][0], format) || "-";
          timeVar2 = this.getFormattedTimeValue(form[key][1], format) || "-";
          replaceString = `${timeVar1} to ${timeVar2}`;
          break;
        case "DATE_TIME_RANGE":
          format = this.defaultDateFormat + ` ${this.getDefaultTimeFormat == 12 ? "HH:mm A" : "HH:mm"}`;
          timeVar1 = this.getGlobalFormattedDateTime(form[key][0]) || "-";
          timeVar2 = this.getGlobalFormattedDateTime(form[key][1]) || "-";
          replaceString = `${timeVar1} to ${timeVar2}`;
          break;
        case "SIGNATURE":
          replaceString = "";
          break;
        case "CURRENCY":
          currencyType = field.validations.currency
            ? field.validations.currency
            : field.selected_fields?.[0]?.validations?.currency
            ? field.selected_fields[0].validations.currency
            : "USD";
          if (!Number.isInteger(form[key])) {
            formattedValue = Number(form[key])?.toFixed(2);
          } else {
            formattedValue = form[key];
          }
          replaceString = `${this.currencySymbolObject[currencyType]}${formattedValue}`;
          break;
        case "LIST":
          if (field.list_data && field.list_data.length) {
            let data = field.list_data.find((e) => e.value == form[key]);
            form[key] = data.name;
            replaceString = form[key];
          }
          break;
        case "HYPER_LINK":
          replaceString = `<a target="MsgWindow" href="${form[key]}">${field.label}</a>`
          break;
        case "FILE":
          if(form[key]?.files?.length) {
            form[key].files.map( e => {
              replaceString += `file-${this.sanitizeFileName(e.name)}, `;
            })
          }
          form[key] = {
            ...form[key],
            dataTableFileKey : replaceString
          }
          if(variablesObj.filesDataObj[dataId]?.length) {
            variablesObj.filesDataObj[dataId].push(form[key])
          } else {
            variablesObj.filesDataObj[dataId] = [form[key]];
          }
          break;
        case "DATA_TABLE":
          if (isFromDataTable) {
            replaceString = "";
          } else {
            replaceString = this.getDataTableView(
              field,
              form,
              key,
              emailTemplate,
              currentEntity,
              variablesObj,
              dataId
            );
          }
          break;
        case "ENTITY_TABLE":
          replaceString = asyncVariablesObj[key]
            ? asyncVariablesObj[key]
            : "--";
          break;
        case "NUMBER":
          replaceString = form[key];
          if (field?.properties?.number_type == "DECIMAL") {
            replaceString = parseFloat(form[key])?.toFixed(2);
          } else if (field.decimals) {
            replaceString = parseFloat(form[key])?.toFixed(field.decimals);
          } else if (!Number.isInteger(form[key])) {
            replaceString = parseFloat(form[key])?.toFixed(2);
          }
          break;
        case "FORMULA":
        case "AGGREGATE_FUNCTION":
          replaceString = this.getDataFieldValue(field, form[key]);
          break;
        case "PHONE_COUNTRY_CODE":
          replaceString =
            form[`${key}_code`]?.["formatInternational"] || form[`${key}_code`];
          break;
        case "MASKED":
          replaceString = form[`${key}_masked`] || "";
          break;
        default:
          replaceString = form[key];
          break;
      }
      return replaceString;
    },
    getRandomKey() {
      return Math.floor(Math.random() * 100000);
    },
    getDataTableView(field, existedData, key, emailTemplate, currentEntity, variablesObj, dataId) {
      if(!existedData[key]?.length) {
        return ""
      }
      let tableData = existedData[key] || [];
      let tableHeadersAndKeys = {};
      let tableKeysAndInputTypes = {};
      let dataTableObject = {};
      if (field.data_table_columns && field.data_table_columns.length) {
        field.data_table_columns.map((e) => {
          if (
            (e.properties?.filed_content &&
              e.properties?.filed_content == "Hide" &&
              !emailTemplate.show_table_hidden_fields) ||
            e.input_type == "SIGNATURE" ||
            (e.input_type == "ENTITY" && e.entity_id == currentEntity._id)
          ) {
            return;
          }
          tableHeadersAndKeys[e.label] = e.key;
          tableKeysAndInputTypes[e.key] = e.inputType;
          dataTableObject[e.key] = e;
        });
      }
      let tableHeaders = Object.keys(tableHeadersAndKeys);
      let tableHTML =
        '<table style="border-collapse: collapse; margin:auto;"><thead><tr>';
      tableHeaders.forEach((header) => {
        tableHTML += `<th style="border: 1px solid black; padding: 8px;">${header}</th>`;
      });
      tableHTML += "</tr></thead><tbody>";

      for (const rowData of tableData) {
        let dataTableFieldsObject = {};
        tableHeaders.map((header) => {
          let input_type = tableKeysAndInputTypes[tableHeadersAndKeys[header]];
          // if (input_type == "FORMULA") {
          //   input_type = dataTableObject[tableHeadersAndKeys[header]]
          //     .result_type
          //     ? dataTableObject[tableHeadersAndKeys[header]].result_type
          //     : "SINGLE_LINE_TEXT";
          // }
          if (input_type == "AGGREGATE_FUNCTION") {
            input_type = dataTableObject[tableHeadersAndKeys[header]]
              .selected_fields[0].result_type
              ? dataTableObject[tableHeadersAndKeys[header]].selected_fields[0]
                  .result_type
              : "SINGLE_LINE_TEXT";
          }
          let dataTableField = dataTableObject[tableHeadersAndKeys[header]];
          const cellValue =
            this.getParsedFieldValue(
              rowData,
              tableHeadersAndKeys[header],
              {},
              dataTableField,
              input_type,
              emailTemplate,
              currentEntity,
              variablesObj,
              dataId,
              true
            ) || "--";
          dataTableFieldsObject[tableHeadersAndKeys[header]] = cellValue;
        });
        const cellValues = tableHeaders.map((header) => {
          const cellValue = dataTableFieldsObject[tableHeadersAndKeys[header]];
          return `<td style="border: 1px solid black; padding: 8px;">${cellValue}</td>`;
        });

        // const cellValues = await Promise.all(cellValuesPromises);
        let rowHTML = `<tr>${cellValues.join("")}</tr>`;
        tableHTML += rowHTML;
      }
      tableHTML += "</tbody></table>";
      return tableHTML;
    },
    getParsedDataTable(
      form,
      key,
      table,
      field,
      emailTemplate,
      currentEntity,
      variablesObj,
      dataId
    ) {
      let {
        // data_table_key: fieldKey,
        data_table_content: tableContent,
        mentionedFields,
        data_table_filters,
        data_table_filter_query,
      } = table;
      key = key.includes("#") ? key.split("#")[1] : key;
      let tableData = form[key];
      if (tableData?.length && data_table_filters?.length)
        tableData = this.checkFilterConditions(
          tableData,
          data_table_filters,
          data_table_filter_query || "AND"
        );
      if (!tableData?.length) return "--";
      if (!mentionedFields?.length) {
        mentionedFields = [];
        let match,
          regex = /\[\[(.*?)\]\]/g;
        while ((match = regex.exec(tableContent)) !== null) {
          mentionedFields.push(match[1]);
        }
      }
      let dataTableFieldsObject = {};
      (field.data_table_columns || []).map((e) => {
        dataTableFieldsObject[e.key.toString()] = e;
      });
      let totalContent = "";
      // let dataTableUrlsObject = {};
      tableData?.forEach((row) => {
        let currentContent = JSON.parse(JSON.stringify(tableContent));
        (mentionedFields || []).map((e) => {
          let field = dataTableFieldsObject[e.toString()];
          if (
            !field ||
            (field.properties?.filed_content &&
              field.properties?.filed_content == "Hide" &&
              !emailTemplate.show_hidden_fields) ||
            field?.input_type == "SIGNATURE"
          ) {
            currentContent = currentContent.replace(`[[${e}]]`, "");
            return null;
          } else {
            let inputType = this.getActualInputTypeOfField(field);
            let fieldValue = this.getParsedFieldValue(
              row,
              e,
              {},
              field,
              inputType,
              emailTemplate,
              currentEntity,
              variablesObj,
              dataId
            );
            if (inputType == "IMAGE" || inputType == "GIF") {
              let reString = `https://app-esigns-io.s3.amazonaws.com/ck_editor_assets/MicrosoftTeams-image_8-1716639572596.png?field=[[${e}]]`;
              if (currentContent.includes(reString)) {
                currentContent = currentContent.replace(reString, fieldValue);
              }
            } else {
              if (["", null, undefined, NaN].includes(fieldValue))
                fieldValue = "-";
              currentContent = currentContent.replace(`[[${e}]]`, fieldValue);
            }
          }
        });
        totalContent = totalContent + currentContent;
      });
      return totalContent;
    },
    getRelationalDataTable(
      dataArray,
      allFields,
      selectedFields,
      emailTemplate,
      currentEntity
    ) {
      if (!dataArray?.length) {
        return "";
      }
      let fieldsObject = this.getFieldsObject(allFields, "template_key");
      let tableHeadersAndKeys = {};
      let tableKeysAndInputTypes = {};
      selectedFields.map((e) => {
        let field = fieldsObject[e];
        if (!field) {
          return;
        }
        tableHeadersAndKeys[field.label] = field.template_key;
        tableKeysAndInputTypes[field.key] = field.input_type;
      });
      let tableHeaders = Object.keys(tableHeadersAndKeys);
      let tableHTML = '<table style="border-collapse: collapse;"><thead><tr>';
      tableHeaders.forEach((header) => {
        let input_type =
          tableKeysAndInputTypes?.[tableHeadersAndKeys?.[header]];
        if (input_type == "PAY_BUTTON") {
          tableHTML += `<th style="border: 1px solid black; padding: 2px; min-width:85px">${header}</th>`;
        } else {
          tableHTML += `<th style="border: 1px solid black; padding: 2px;">${header}</th>`;
        }
      });
      tableHTML += "</tr></thead><tbody>";
      let tableDataArray = [];
      dataArray.map(async (data) => {
        let dataObject = {};
        (data?.templates_data || []).forEach((el) => {
          dataObject[el.template_id] = el;
        });
        const parsedRowData = selectedFields.map((e) => {
          const splitArray = e.split("#");
          const templateId = splitArray[0];
          let templateKey = splitArray[1];
          if (templateKey.includes(":")) {
            templateKey = templateKey.split(":")[1];
          }
          const existedData = data?.entityData?.[templateId];
          if (!existedData) {
            return { [e]: "-" };
          }
          const fieldData = this.getParsedFieldValue(
            existedData,
            templateKey,
            {},
            fieldsObject[e],
            fieldsObject[e].inputType,
            emailTemplate,
            currentEntity
          );
          return { [e]: fieldData ? fieldData : "-" };
        });

        const rowData = Object.assign({}, ...parsedRowData);
        tableDataArray.push(rowData);
      });
      for (const rowData of tableDataArray) {
        const cellValues = tableHeaders.map((header) => {
          const cellValue = rowData[tableHeadersAndKeys[header]];
          return `<td style="border: 1px solid black; padding: 2px;">${cellValue}</td>`;
        });

        let rowHTML = `<tr>${cellValues.join("")}</tr>`;
        tableHTML += rowHTML;
      }
      tableHTML += "</tbody></table>";
      return tableHTML;
    },
  },
};
